import React, { useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Header from "../header"
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import * as ReactMobileDetect from 'react-device-detect';
import Sidebar from "../sidebar"
import SwipeableViews from 'react-swipeable-views';
import './menu.scss'
import SEO from '../seo'
import '../../styles/global.css'
import Helmet from "react-helmet"
import SettingMenu from '../settingbutton';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function indicatorColorChange(index) {
    switch (index){
      case 0:
        return "#FF5577"
      case 1:
        return "#27ae60"
      case 2:
        return "#1DA1F2"
      case 3:
        return "#34495e"
      case 4:
        return "#EBBF0B"
      case 5:
        return "#9b59b6"
      case 6:
        return "#247BA0"
      case 7:
        return "#FF9999"
      case 8:
        return "#f39c12"
      case 9:
        return "#F45B69"
      case 10:
        return "#EEFFDD"
    }
  
}

class Menu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      tabIndex:props.themeContext.tabNumber,
      indicator:{
        style: {
          height: 5,
          backgroundColor: "#D97D54"
        }
      }
    }
    this.setTabIndex = this.setTabIndex.bind(this);
  }

  componentDidMount() {
    // on client side we check if we are on mobile
    // or not
    this.setState({
      isMobile: ReactMobileDetect.isMobile,
    })
  }

  componentWillMount(){
    var tabIndexlocal = this.props.themeContext.tabNumber
    this.setState({
      tabIndex:tabIndexlocal,
      indicator:{
        style:{
          height: 5,
          backgroundColor : indicatorColorChange(tabIndexlocal) 
          }
        }
    })
  }


  setTabIndex(tabnumber){
    this.setState({tabIndex:tabnumber});
    this.props.themeContext.changeTab(tabnumber)
  }


  render(){
    //TOP画面の時
    if (this.props.page === 'top') {
      return (
        <>
        <SEO title="話題に反応！" description="2chやTwitterなどで話題のニュースについてまとめたサイト" />
        <ReactMobileDetect.CustomView condition={this.state.isMobile} id="top-mobile">
          <Helmet
              bodyAttributes={{
                  class: 'mobilebackground'
              }}
          />
          <Header themeContext={this.props.themeContext}/>
          <nav className="menu menu--fixed">
            <Paper square>
              <Tabs value={this.state.tabIndex}
                fullWidth
                variant="scrollable"
                onChange={(event, newValue) => {this.setState({tabIndex:newValue});this.props.themeContext.changeTab(newValue)  }}
                style={{background: '#fff',fontSize:'10%'}}
                >

                <Tab label="TOP" className="menu-tab-mobile" style={{ backgroundColor: '#FF5577' }} />
                <Tab label="エンタメ" className="menu-tab-mobile" style={{ backgroundColor: '#27ae60'}} />
                <Tab label="国内" className="menu-tab-mobile" style={{ backgroundColor: '#1DA1F2'}} />
                <Tab label="海外" className="menu-tab-mobile" style={{ backgroundColor: '#34495e'}} />
                <Tab label="おもしろ" className="menu-tab-mobile" style={{ backgroundColor: '#EBBF0B'}} />
                <Tab label="スポーツ" className="menu-tab-mobile" style={{ backgroundColor: '#9b59b6'}} />
                <Tab label="IT" className="menu-tab-mobile" style={{ backgroundColor: '#247BA0' }} />
                <Tab label="グルメ" className="menu-tab-mobile" style={{ backgroundColor: '#FF9999'}} />
                <Tab label="趣味" className="menu-tab-mobile" style={{ backgroundColor: '#f39c12'}} />
                <Tab label="楽しむ" className="menu-tab-mobile" style={{ backgroundColor: '#F45B69'}} />
                <Tab label="話題" className="menu-tab-mobile" style={{ backgroundColor: '#EEFFDD'}} />
              </Tabs>
            </Paper>
            <SettingMenu setTabIndex={this.setTabIndex}/>
            <SwipeableViews index={this.state.tabIndex} onChangeIndex={(newValue) => {this.setState({tabIndex:newValue});this.props.themeContext.changeTab(newValue) }}>
              <div className="layout_main_contents-mobile">{this.props.children[0]}</div>
              <div className="layout_main_contents-mobile">{this.props.children[1]}</div>
              <div className="layout_main_contents-mobile">{this.props.children[2]}</div>
              <div className="layout_main_contents-mobile">{this.props.children[3]}</div>
              <div className="layout_main_contents-mobile">{this.props.children[4]}</div>
              <div className="layout_main_contents-mobile">{this.props.children[5]}</div>
              <div className="layout_main_contents-mobile">{this.props.children[6]}</div>
              <div className="layout_main_contents-mobile">{this.props.children[7]}</div>
              <div className="layout_main_contents-mobile">{this.props.children[8]}</div>
              <div className="layout_main_contents-mobile">{this.props.children[9]}</div>
              <div className="layout_main_contents-mobile">{this.props.children[10]}</div>
            </SwipeableViews>
          </nav>
        </ReactMobileDetect.CustomView>

<ReactMobileDetect.CustomView condition={!this.state.isMobile} id="top-pc">
<Header themeContext={this.props.themeContext}/>
<nav className="menu menu--fixed">
    <Tabs value={this.state.tabIndex}
      indicatorColor="primary"
      textColor="primary"
      onChange={(event, newValue) => {this.setState({
        tabIndex:newValue,
        indicator:{
          style:{
            height: 5,
            backgroundColor : indicatorColorChange(newValue) 
            }
          }})
          this.props.themeContext.changeTab(newValue) 
        } }
        style={{backgroundColor:'white'}}
      aria-label="simple tabs example"
      TabIndicatorProps={this.state.indicator}
      centered
      >
      <Tab label="TOP" style={{ color: 'black' ,minWidth: 100}}/>
      <Tab label="エンタメ" style={{ color: 'black' ,minWidth: 100}}/>
      <Tab label="国内" style={{ color: 'black' ,minWidth: 100}}/>
      <Tab label="海外" style={{ color: 'black' ,minWidth: 100}}/>
      <Tab label="おもしろ" style={{ color: 'black' ,minWidth: 100}}/>
      <Tab label="スポーツ" style={{ color: 'black' ,minWidth: 100}}/>
      <Tab label="IT" style={{ color: 'black' ,minWidth: 100}}/>
      <Tab label="グルメ" style={{ color: 'black' ,minWidth: 100}}/>
      <Tab label="趣味" style={{ color: 'black' ,minWidth: 100}}/>
      <Tab label="楽しむ" style={{ color: 'black' ,minWidth: 100}}/>
      <Tab label="話題" style={{ color: 'black' ,minWidth: 100}} />
    </Tabs>
  <TabPanel value={this.state.tabIndex} index={0}>
    <div className="menu--tabpanel">
      {this.props.children[0]}
      <Sidebar category={this.props.category} />
    </div>
  </TabPanel>
  <TabPanel value={this.state.tabIndex} index={1}>
    <div className="menu--tabpanel">
      {this.props.children[1]}
      <Sidebar category={this.props.category} />
    </div>
  </TabPanel>
  <TabPanel value={this.state.tabIndex} index={2}>
    <div className="menu--tabpanel">
      {this.props.children[2]}
      <Sidebar category={this.props.category} />
    </div>
  </TabPanel>
  <TabPanel value={this.state.tabIndex} index={3}>
    <div className="menu--tabpanel">
      {this.props.children[3]}
      <Sidebar category={this.props.category} />
    </div>
  </TabPanel>
  <TabPanel value={this.state.tabIndex} index={4}>
    <div className="menu--tabpanel">
      {this.props.children[4]}
      <Sidebar category={this.props.category} />
    </div>
  </TabPanel>
  <TabPanel value={this.state.tabIndex} index={5}>
    <div className="menu--tabpanel">
      {this.props.children[5]}
      <Sidebar category={this.props.category} />
    </div>
  </TabPanel>
  <TabPanel value={this.state.tabIndex} index={6}>
    <div className="menu--tabpanel">
      {this.props.children[6]}
      <Sidebar category={this.props.category} />
    </div>
  </TabPanel>
  <TabPanel value={this.state.tabIndex} index={7}>
    <div className="menu--tabpanel">
      {this.props.children[7]}
      <Sidebar category={this.props.category} />
    </div>
  </TabPanel>
  <TabPanel value={this.state.tabIndex} index={8}>
    <div className="menu--tabpanel">
      {this.props.children[8]}
      <Sidebar category={this.props.category} />
    </div>
  </TabPanel>
  <TabPanel value={this.state.tabIndex} index={9}>
    <div className="menu--tabpanel">
      {this.props.children[9]}
      <Sidebar category={this.props.category} />
    </div>
  </TabPanel>
  <TabPanel value={this.state.tabIndex} index={10}>
    <div className="menu--tabpanel">
      {this.props.children[10]}
      <Sidebar category={this.props.category} />
    </div>
  </TabPanel>
</nav>
</ReactMobileDetect.CustomView>
</>
      );
    }
    //top以外の場合
    else {
      return (
      <>
      <SEO title="話題に反応！" description="2chやTwitterなどで話題のニュースについてまとめたサイト" />
        <ReactMobileDetect.CustomView condition={this.state.isMobile} id="nottop-mobile">
        <Header themeContext={this.props.themeContext}/>
        <nav className="menu menu--fixed">
          <Paper square>
            <Tabs value={this.state.tabIndex}
              fullWidth
              variant="scrollable"
              style={{background: '#fff',fontSize:'10%'}}
              aria-label="simple tabs example"
              >
              <Link to="/" className="menu-tab-mobile" state={{ tabnumber: 0 }} style={{ textDecoration: 'initial' }}>
                <Tab label="TOP" style={{ color: 'black',backgroundColor: '#FF5577' }}  onClick={()=>{this.props.themeContext.changeTab(0)}} />
              </Link>
              <Link to="/" className="menu-tab-mobile" state={{ tabnumber: 1 }} style={{ textDecoration: 'initial' }}>
                <Tab label="エンタメ" style={{ color: 'black',backgroundColor: '#27ae60'}} onClick={()=>{this.props.themeContext.changeTab(1)}} />
              </Link>
              <Link to="/" className="menu-tab-mobile" state={{ tabnumber: 2 }} style={{ textDecoration: 'initial' }}>
                <Tab label="国内" style={{ color: 'black',backgroundColor: '#1DA1F2' }} onClick={()=>{this.props.themeContext.changeTab(2)}} /> 
              </Link>
              <Link to="/" className="menu-tab-mobile" state={{ tabnumber: 3 }} style={{ textDecoration: 'initial' }}>
                <Tab label="海外" style={{ color: 'black', backgroundColor: '#34495e' }} onClick={()=>{this.props.themeContext.changeTab(3)}} /> 
              </Link>
              <Link to="/" className="menu-tab-mobile" state={{ tabnumber: 5 }} style={{ textDecoration: 'initial' }}>
                <Tab label="おもしろ" style={{ color: 'black',backgroundColor: '#EBBF0B' }} onClick={()=>{this.props.themeContext.changeTab(4)}} /> 
              </Link>
              <Link to="/" className="menu-tab-mobile" state={{ tabnumber: 4 }} style={{ textDecoration: 'initial' }}>
                <Tab label="スポーツ" style={{ color: 'black' ,backgroundColor: '#9b59b6' }} onClick={()=>{this.props.themeContext.changeTab(5)}} /> 
              </Link>
              <Link to="/" className="menu-tab-mobile" state={{ tabnumber: 6 }} style={{ textDecoration: 'initial' }}>
                <Tab label="IT" style={{ color: 'black',backgroundColor: '#247BA0'}} onClick={()=>{this.props.themeContext.changeTab(6)}} /> 
              </Link>
              <Link to="/" className="menu-tab-mobile" state={{ tabnumber: 7 }} style={{ textDecoration: 'initial' }}>
                <Tab label="グルメ" style={{ color: 'black', backgroundColor: '#FF9999' }} onClick={()=>{this.props.themeContext.changeTab(7)}} /> 
              </Link>
              <Link to="/" className="menu-tab-mobile" state={{ tabnumber: 8 }} style={{ textDecoration: 'initial' }}>
                <Tab label="趣味" style={{ color: 'black', backgroundColor: '#f39c12' }} onClick={()=>{this.props.themeContext.changeTab(8)}} /> 
              </Link>
              <Link to="/" className="menu-tab-mobile" state={{ tabnumber: 9 }} style={{ textDecoration: 'initial' }}>
                <Tab label="楽しむ" style={{ color: 'black', backgroundColor: '#F45B69'}} onClick={()=>{this.props.themeContext.changeTab(9)}} /> 
              </Link>
              <Link to="/" className="menu-tab-mobile"  state={{ tabnumber: 10 }} style={{ textDecoration: 'initial' }}>
                <Tab label="話題" style={{ color: 'black', backgroundColor: '#EEFFDD' }} onClick={()=>{this.props.themeContext.changeTab(10)}} /> 
              </Link>
            </Tabs>
          </Paper>
          <div id="template-mobile-content" className="details">
            <div className="template-mobile-wrap">
              {this.props.children}
            </div>
          </div>
        </nav>
        </ReactMobileDetect.CustomView>
      <ReactMobileDetect.CustomView condition={!this.state.isMobile} id="nottop-pc">
          <Header themeContext={this.props.themeContext}/>
          <nav className="menu menu--fixed">
              <Tabs value={this.state.tabIndex}
                indicatorColor="primary"
                textColor="primary"
                
                aria-label="simple tabs example"
                TabIndicatorProps={this.state.indicator}
                centered
              >
                <Link to="/" state={{ tabnumber: 0 }} style={{ textDecoration: 'initial' }}>
                  <Tab label="TOP" style={{ color: 'black' ,minWidth: 100}} onClick={()=>{this.props.themeContext.changeTab(0)}} />
                </Link>
                <Link to="/" state={{ tabnumber: 1 }} style={{ textDecoration: 'initial' }}>
                  <Tab label="エンタメ" style={{ color: 'black' ,minWidth: 100}} onClick={()=>{this.props.themeContext.changeTab(1)}} />
                </Link>
                <Link to="/" state={{ tabnumber: 2 }} style={{ textDecoration: 'initial' }}>
                  <Tab label="国内" style={{ color: 'black' ,minWidth: 100}} onClick={()=>{this.props.themeContext.changeTab(2)}} /> 
                </Link>
                <Link to="/" state={{ tabnumber: 3 }} style={{ textDecoration: 'initial' }}>
                  <Tab label="海外" style={{ color: 'black' ,minWidth: 100}} onClick={()=>{this.props.themeContext.changeTab(3)}} /> 
                </Link>
                <Link to="/" state={{ tabnumber: 5 }} style={{ textDecoration: 'initial' }}>
                  <Tab label="おもしろ" style={{ color: 'black' ,minWidth: 100}} onClick={()=>{this.props.themeContext.changeTab(4)}} /> 
                </Link>
                <Link to="/" state={{ tabnumber: 4 }} style={{ textDecoration: 'initial' }}>
                  <Tab label="スポーツ" style={{ color: 'black' ,minWidth: 100}} onClick={()=>{this.props.themeContext.changeTab(5)}} /> 
                </Link>
                <Link to="/" state={{ tabnumber: 6 }} style={{ textDecoration: 'initial' }}>
                  <Tab label="IT" style={{ color: 'black' ,minWidth: 100}} onClick={()=>{this.props.themeContext.changeTab(6)}} /> 
                </Link>
                <Link to="/" state={{ tabnumber: 7 }} style={{ textDecoration: 'initial' }}>
                  <Tab label="グルメ" style={{ color: 'black' ,minWidth: 100}} onClick={()=>{this.props.themeContext.changeTab(7)}} /> 
                </Link>
                <Link to="/" state={{ tabnumber: 8 }} style={{ textDecoration: 'initial' }}>
                  <Tab label="趣味" style={{ color: 'black' ,minWidth: 100}} onClick={()=>{this.props.themeContext.changeTab(8)}} /> 
                </Link>
                <Link to="/" state={{ tabnumber: 9 }} style={{ textDecoration: 'initial' }}>
                  <Tab label="楽しむ" style={{ color: 'black' ,minWidth: 100}} onClick={()=>{this.props.themeContext.changeTab(9)}} /> 
                </Link>
                <Link to="/" state={{ tabnumber: 10 }} style={{ textDecoration: 'initial' }}>
                  <Tab label="話題" style={{ color: 'black' ,minWidth: 100}} onClick={()=>{this.props.themeContext.changeTab(10)}} /> 
                </Link>
              </Tabs>
      
            </nav>

            <div className="menu--tabpanel" style={{padding:'24px'}}>
              <div id="inner-content" className="wrap">
                {this.props.children}
                {
                  this.props.template !== 'movie' &&( 
                <Sidebar category={this.props.category} />
                    )
                  }
                </div>
            </div>
            </ReactMobileDetect.CustomView>
            </>
      );
    }
  }
}

export default Menu