import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import "./sidebar.scss"
import switchCategory from '../../utils/switchCategory.jsx';

const CategoryPosts = (props) => (
  <StaticQuery
    query={graphql`
    query {
      allFiveChJson{
          nodes {
            title
            date
            categories
            top_image
            res{
              uid
                }
            fields {
                slug
                }
          }
        }
        ranking {
          popularArticle
        }
      }
    `}

    render={(data) => {
      const postlists = data.allFiveChJson.nodes
      const rankingData = data.ranking.popularArticle

      var rankingArray = []
      for (var i = 0; i < postlists.length; i++) {
        for(var j = 0; j < rankingData.length; j++){
        if('/'+postlists[i].fields.slug === (rankingData[j])){
        rankingArray.push(postlists[i])
        }
      }
    }
      
      var posts
      if (props.category === 'all') {
        posts = postlists
      }
      else {
        posts = postlists.filter((category) => {
          return (category.categories === props.category)
        })
      }
      return (
        <aside className="sidebar">
              <section className="sidebar--ranking">
            <h2 >ランキング</h2>
            <div className="sidebar--ranking__inner">
            {rankingArray.map(
              ({
                title,
                categories,
                fields: { slug },
                top_image

              }) => (
                <article className="sidebar--onearticle">
                  <Link to={slug} className="postlist--onearticle__link" state={{ tabnumber: switchCategory(categories) }}>
                  <div className="sidebar--onearticle__image">
                  <img
                      src ={top_image}
                      className="sidebar--onearticle__image__img"
                                            />
                      </div>
                      <h4 className="sidebar--onearticle__title">
                      {title}
                      </h4>
                  </Link>
                </article>
                )
            )}
            </div>
            </section>
          </aside>
      );
    }}
  />
)
export default CategoryPosts