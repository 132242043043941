import React from 'react';
import Menu from "../menu"
import "./layout.scss"
import { isMobile } from 'react-device-detect';


export default (props) => {
  
  var globalDesign = ""
  if(!isMobile){
    globalDesign="flex"
  }

    if (props.page === 'top') {
      return (
        
          <div className={globalDesign}>
            <div className="layout--mainMenu">
              <Menu page={props.page} tabnumber={props.tabnumber} themeContext={props.themeContext} template={props.template}>
                {props.children}
              </Menu>
            </div>
          </div>
       
      )
    }
    else {
      return (
          <div className="layout--flex">
            <div className="layout--mainMenu">
              <Menu page={props.page} tabnumber={props.tabnumber} themeContext={props.themeContext} template={props.template}>
              {props.children}
              </Menu>
            </div>
          </div>
        
      )

    }
}

