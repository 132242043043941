export default function switchCategory(item) {
    switch (item) {
        case 'TOP':
            return 0;
        case 'エンタメ':
            return 1;
        case '国内':
            return 2;
        case '海外':
            return 3;
        case 'スポーツ':
            return 4;
        case '投資':
            return 5;
        case 'IT':
            return 6;
        case 'グルメ':
            return 7;
        case '車':
            return 8;

    }
}