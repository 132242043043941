import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import CloseIcon from '@material-ui/icons/Close';
import DehazeIcon from '@material-ui/icons/Dehaze';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import GenreSwitchMobile from '../switchgenremobile'

const styles = {
  slideContainer: {
    height: '100vh',
  },
  slide: {
    padding: 15,
    minHeight: 100,
    color: '#fff',
  },
  slide1: {
    backgroundColor: '#FEA900',
  },
  slide2: {
    backgroundColor: '#B3DC4A',
  },
  scroll: {
    height: 100,
    overflow: 'scroll',
  },
  slide3: {
    height: 200,
    backgroundColor: '#6AC0FF',
  },
};
{/*
export default  class SettingMenu extends React.Component{
    constructor(){
      super();
      this.state = {
        isArticleVisible: false,
        timeout: false,
        articleTimeout: false,
        loading: 'is-loading',
      }
      //コールバック関数として実行する際にちゃんと関数内の「this」が機能するように、constructor()内でthisをbindしておく必要があります。
      this.handleOpenArticle = this.handleOpenArticle.bind(this)
      this.handleCloseArticle = this.handleCloseArticle.bind(this)
      this.setWrapperRef = this.setWrapperRef.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }
  
    componentDidMount () {
      this.timeoutId = setTimeout(() => {
          this.setState({loading: ''});
      }, 100);
      document.addEventListener('mousedown', this.handleClickOutside);
    }
  
    componentWillUnmount () {
      if (this.timeoutId) {
          clearTimeout(this.timeoutId);
      }
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  
    //コンポーネントの外でもクリックイベントを検出するようにする
    setWrapperRef(node) {
      this.wrapperRef = node;
    }
  
    handleOpenArticle() {
    
      this.setState({
        isArticleVisible: !this.state.isArticleVisible,
      })
  
      setTimeout(() => {
        this.setState({
          timeout: !this.state.timeout
        })
      }, 325)
  
      setTimeout(() => {
        this.setState({
          articleTimeout: !this.state.articleTimeout
        })
      }, 350)
    }
  
    handleClickOutside(event) {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        if (this.state.isArticleVisible) {
          this.handleCloseArticle();
        }
      }
    }
  
    handleCloseArticle() {
  
      this.setState({
        articleTimeout: !this.state.articleTimeout
      })
  
      setTimeout(() => {
        this.setState({
          timeout: !this.state.timeout
        })
      }, 325)
  
      setTimeout(() => {
        this.setState({
          isArticleVisible: !this.state.isArticleVisible,
        })
      }, 350)
  
    }
  
    render(){
      return(
        <>
        <nav>
          <SettingButton >
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={() => {
                if(this.state.isArticleVisible)
                  this.handleOpenArticle()
                else
                this.handleCloseArticle()
            }
          }
              >
            {this.state.isArticleVisible && <CloseIcon />}
            {!this.state.isArticleVisible && <DehazeIcon />}     
            </IconButton>
          </SettingButton>
        </nav>
          <SettingModal
                isArticleVisible={this.state.isArticleVisible}
                timeout={this.state.timeout}
                articleTimeout={this.state.articleTimeout}
                onCloseArticle={this.handleCloseArticle}
                setWrapperRef={this.setWrapperRef}
                onPause={this.onPause}
                onPlay={this.onPlay}
                playing={this.state.playing}
              />
          </>
      )
    }
  }

*/}
export default function SettingMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(0);

    const handleChange = value => {
      setAnchorEl(value);
    };
  
    const handleClick = () => {
      if(anchorEl == 0)
        setAnchorEl(1);
      else
      setAnchorEl(0);
    };
  return (
      <>
    <SettingButton >
    <IconButton
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      onClick={handleClick}
      >
     {anchorEl == 1 && <CloseIcon />}
     {anchorEl == 0 && <DehazeIcon />}
    
    </IconButton>
    </SettingButton>
    <SettingPanel theme={anchorEl} style={{position:'fixed',zIndex:'3000000',height:'100%',width:'100%',top:0,left:0}}>
    <SwipeableViews index={anchorEl} onChangeIndex={handleChange} containerStyle={styles.slideContainer} axis="y" resistance>
      <Slide1>slide n°1</Slide1>
      <Slide2>
      <GenreSwitchMobile setTabIndex={props.setTabIndex}/>
      </Slide2>

    </SwipeableViews>
    </SettingPanel>
    </>
  );
}


const SettingPanel = styled.div`
display: ${props => (props.theme == 1 ? 'inherit' : 'none')};
`;


const SettingButton = styled.div`
position: fixed;
right: 10px;
bottom: 30px;
display: inline-block;
width: 50px;
height: 50px;
border-radius: 50%;
background: skyblue;
text-align:center;
line-height: 40px;
z-index:4000000;
`;

const Slide1 = styled.div`
opacity:1;
padding: 15;
min-height: 100vh;
`;

const Slide2 = styled.div`
background-color: rgba(0,0,0,0.9);
padding: 15;
min-height: 100vh;
color: #fff;
position: fixed;
width: 100%;
`;