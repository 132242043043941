import React, { useContext } from 'react';

// import context
import  ThemeContext  from '../context/ThemeContext'; // 追加分
import DarkmodeSwitch from '../darkmode'
import './switchgenremobile.scss'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { backgrounds } from 'polished';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop:20
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    width:'100%',
  },
}));

const GenreSwitchMobile = (props) => {
  const value = useContext(ThemeContext);

  const classes = useStyles();

  return (
    <ThemeContext.Consumer>
    {theme => (
      <>
        <h2 style={{textAlign:'center'}}>
            ソース元
        </h2>
        <nav class="container-mobile">
          <div class="radio-tile-group">
            <div class="input-container-mobile">
              <input id="all" class="radio-button" type="radio" name="radio" value="all" checked={value.quoteSource=== 'all'}
                    onChange={() => theme.changeGenre('all')}/>
              <div class="radio-tile">
                <label for="all" class="radio-tile-label">all</label>
              </div>
            </div>
            <div class="input-container-mobile">
              <input id="5ch" class="radio-button" type="radio" name="radio" value="5ch" checked={value.quoteSource === '5ch'}
                    onChange={() => theme.changeGenre('5ch')} />
              <div class="radio-tile">
                <label for="5ch" class="radio-tile-label">5ch</label>
              </div>
            </div>
            <div class="input-container-mobile">
              <input id="twitter" class="radio-button" type="radio" name="radio" value="twitter" checked={value.quoteSource === 'twitter'}
              onChange={() => theme.changeGenre('twitter')}/>
              <div class="radio-tile">
                <label for="twitter" class="radio-tile-label">twitter</label>
              </div>
            </div>
          </div>
          
          <h2 style={{textAlign:'center'}}>
            ジャンル
          </h2>

          <Grid container spacing={1} className={classes.root}>
            <Grid container item xs={15} spacing={3} className='waku'>
              <Grid item xs={4}>
              <Button variant="outlined" className={classes.paper} color="primary" onClick={() => props.setTabIndex(0)}>
                 TOP
              </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="outlined" className={classes.paper} color="primary" onClick={() => props.setTabIndex(1)}>
                  エンタメ
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={15} spacing={3} className='waku'>
              <Grid item xs={4}>
                <Button variant="outlined" className={classes.paper} color="primary" onClick={() => props.setTabIndex(2)}>
                  国内
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="outlined" className={classes.paper} color="primary" onClick={() => props.setTabIndex(3)}>
                  海外
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={15} spacing={3} className='waku'>
              <Grid item xs={4}>
                <Button variant="outlined" className={classes.paper} color="primary" onClick={() => props.setTabIndex(4)}>
                  おもしろ
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="outlined" className={classes.paper} color="primary" onClick={() => props.setTabIndex(5)}>
                  スポーツ
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={15} spacing={3} className='waku'>
              <Grid item xs={4}>
                <Button variant="outlined" className={classes.paper} color="primary" onClick={() => props.setTabIndex(6)}>
                  IT
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="outlined" className={classes.paper} color="primary" onClick={() => props.setTabIndex(7)}>
                  グルメ
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={15} spacing={3} className='waku'>
              <Grid item xs={4}>
                <Button variant="outlined" className={classes.paper} color="primary" onClick={() => props.setTabIndex(8)}>
                  趣味
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="outlined" className={classes.paper} color="primary" onClick={() => props.setTabIndex(9)}>
                  楽しむ
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </nav>
    </>
  )}
  </ThemeContext.Consumer>
  );
};

export default GenreSwitchMobile;