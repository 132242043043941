import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, Link, graphql } from "gatsby"

import * as ReactMobileDetect from 'react-device-detect';
import "./header.scss";
import Img from 'gatsby-image'
import Search from '../search'
import styled, { createGlobalStyle } from 'styled-components';
//import DarkmodeSwitch from '../darkmode'

// import style
//import { LIGHT_MODE, DARK_MODE } from '../darkmode/Theme';
import  GenreSwitch  from '../switchgenre';

class HeaderComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMobile: false
    }
  }

  componentDidMount() {
    this.setState({
      isMobile: ReactMobileDetect.isMobile,
    })

  }
  render () {
    return (
<>
          {/*<GlobalStyle theme={theme.dark ? 'dark' : 'light'} />*/}
          <ReactMobileDetect.CustomView condition={this.state.isMobile}>
            <header className="header-mobile">
              <Helmet
                htmlAttributes={{ lang: "ja" }}
                title={this.props.title}
                meta={[
                  {
                    name: "description",
                    content: this.props.description,
                  },
                ]}
              />
              <div className="header_inner">
                <Link to="/" >
                  <Img fixed={this.props.fixed} className='header-mobile-image ' />
                </Link>
              </div>
            </header>
          </ReactMobileDetect.CustomView>
          <ReactMobileDetect.CustomView condition={!this.state.isMobile}>
            <header className="header">
              <Helmet
                htmlAttributes={{ lang: "ja" }}
                title={this.props.title}
                meta={[
                  {
                    name: "description",
                    content: this.props.description,
                  },
                ]}
              />
              <div className="header_inner">
                <Link to="/" className="header_logo">
                  <Img fixed={this.props.fixed} />
                </Link>
                <GenreSwitch switchGenreStateClick={this.props.themeContext.changeGenre}/>
                <div className="header_search">
                  <Search />
                </div>
              </div>
           </header>
         </ReactMobileDetect.CustomView>
         </>
    )
  }
}


export default (props) => {
  const {
    site: {
      siteMetadata: { title, description },
    },
    file: {
      childImageSharp: { fixed }
    }
  } = useStaticQuery(
    graphql`
      query {
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp{
        fixed(width: 200) {
            ...GatsbyImageSharpFixed
        }
    }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
    `
  )


  return(
    <HeaderComponent title={title} description={description} fixed={fixed} themeContext={props.themeContext}/>
  )

}

{/*}
// Global style
const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    overflow-y: scroll;
  }

  body {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lato';
    line-height: 1.8;
    word-wrap: break-word;
    font-kerning: normal;
    color: ${props => (props.theme === 'light' ? LIGHT_MODE.text : DARK_MODE.text)};
    background-color: ${props => (props.theme === 'light' ? LIGHT_MODE.background : DARK_MODE.background)};
  }
`;
*/}

