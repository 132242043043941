import React, { useContext } from 'react';
import styled from 'styled-components';
// import assets
import Light from '../../../assets/images/sun.png';
import Dark from '../../../assets/images/moon.png';
// import context
import  ThemeContext  from '../context/ThemeContext'; // 追加分

import './switchgenre.scss'

const GenreSwitch = (props) => {
  const value = useContext(ThemeContext);


  return (
    <div class="genrecontainer">
  <div class="radio-tile-group">
    <div class="input-container">
      <input id="all" class="radio-button" type="radio" name="radio" value="all" checked={value.quoteSource=== 'all'}
            onChange={() => props.switchGenreStateClick('all')}/>
      <div class="radio-tile">
        <label for="all" class="radio-tile-label">ALL</label>
      </div>
    </div>

    <div class="input-container">
      <input id="5ch" class="radio-button" type="radio" name="radio" value="5ch" checked={value.quoteSource === '5ch'}
            onChange={() => props.switchGenreStateClick('5ch')} />
      <div class="radio-tile">
        <label for="5ch" class="radio-tile-label">5ch</label>
      </div>
    </div>

    <div class="input-container">
      <input id="twitter" class="radio-button" type="radio" name="radio" value="twitter" checked={value.quoteSource === 'twitter'}
            onChange={() => props.switchGenreStateClick('twitter')}/>
      <div class="radio-tile">
        <label for="twitter" class="radio-tile-label">Twitter</label>
      </div>
    </div>
  </div>
</div>
  );
};

export default GenreSwitch;